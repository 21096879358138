<template>
  <div>
    <v-row class="soon">
      <v-col cols="12">
        <img
          class="soon-img"
          src="~/assets/images/comming-soon.svg"
          alt="comming"
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "CommingSoon",
  methods: {}
};
</script>

<style lang="scss" scoped>
.soon {
  display: grid;
  place-items: center;
  text-align: center;
  margin: 80px auto 100px;
  .soon-img {
    max-width: 60%;
    margin-bottom: 100px;
  }
  p {
    font-size: 33px;
    font-weight: bold;
    line-height: 1.85;
    text-align: center;
    color: #37474f;
  }
}
.main-btn {
  background-color: #464688 !important;
  font-size: 16px !important;
  font-weight: bold;
  padding: 24px 16px !important;
  img {
    width: 32px !important;
  }
}

@media (max-width: 991px) {
  .soon {
    margin: 50px auto 100px;
    .soon-img {
      max-width: 80%;
      margin-bottom: 40px;
    }
    p {
      font-size: 18px;
    }
  }
  .main-btn {
    font-size: 14px !important;
    padding: 24px 14px !important;
    img {
      width: 25px !important;
    }
  }
}
</style>
